body {
    background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), 
    url("/public/7492091.jpg");    
}

.p-card {
    background: rgba(255, 255, 255, 0.6) !important;
}

img.logo {
    background-color: rgba(255, 255, 255, 0.25);
    height: 200px;
    border: 0px transparent;
    margin-top: -35px;
    margin-bottom: -30px;
}

.slideLink {
    display: block;
}

.slideLinkNoHref {
    display: block;
    text-decoration: none;
}

.slideImage {
    max-width: 85%;
}

.control-arrow {
    background: rgba(0,0,0,.4) !important;
    height: 50%;
    top: 25% !important; 
}

.newsImage {
    max-width: 50%;
} 

.p-menuitem-text > a {
    text-decoration: none;
}